import React, { useMemo } from "react"
import styled from "@emotion/styled"
import Layout from "components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import { ArticleRichTextOptions } from "utils/richText"
import Container from "components/container"
import { H1, H3, P } from "components/headings"
import Gears from "images/illustrations/gears.svg"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"

import Link from "components/Link"
import { ArticleContent } from "./articleTemplate"

export const query = graphql`
	query($images: [String], $slug: String!) {
		allContentfulAsset(filter: { file: { url: { in: $images } }, node_locale: { eq: "en-US" } }) {
			edges {
				node {
					...ArticleImage
				}
			}
		}
		contentfulEngineeringPost(slug: { eq: $slug }) {
            author
            date
            title
            tag
		}
	}
`
const StyledContainer = styled(Container)`
	display: flex;
	padding: 8rem 1.6rem !important;
`
const ArticleTitle = styled.h1 `
    margin-bottom: 5rem;
`
const Aside = styled.aside`
	min-width: 384px;
	max-width: 384px;
	margin-right: 80px;
	display: none;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.MD)} {
		display: flex;
	}

	button {
		font-size: 2.4rem;
		line-height: 4rem;
		font-weight: bold;
		border: none;
		background: none;
		padding: 0;
		text-align: left !important;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
`
const CategoryHeader = styled.div`
	background: #662abc;
	color: white;
	margin-top: -70px;
	padding-top: 70px;
	height: 555px;

	${mediaBreakpoint(BreakPoints.MD)} {
		height: 405px;
	}
`
const CategoryLinks = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;

	a {
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: #181818;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.selected {
		font-weight: 800;
	}
`
const HeroContainer = styled(Container)`
	margin-top: 60px;
	height: calc(100% - 50px);
	display: flex;
	h3,
	p {
		color: white !important;
	}
	h3 {
		text-transform: capitalize;
	}
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`

const Left = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;

	${mediaBreakpoint(BreakPoints.MD)} {
		margin-top: 0px;
		flex-grow: 1;
		height: 100%;
	}
`

const Right = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	img {
		object-fit: cover;
		${mediaBreakpoint(BreakPoints.MD)} {
			height: calc(100% + 30px);
		}
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-grow: 1;
	}
`

const EngineeringPost = ({ data, pageContext }) => {
	const { slug, markdown, allPosts } = pageContext
	const { contentfulEngineeringPost } = data
	const { author,title, date, tag } = contentfulEngineeringPost

	const { node: currentPost } = allPosts.find(post => post.node.slug === slug)
	const categoryLinks = allPosts
		.filter(post => post.node.category === currentPost.category)
		.map(post => {
			return {
				title: post.node.title,
				slug: post.node.slug
			}
		})

	const images = useMemo(() => {
		return data.allContentfulAsset.edges.reduce((acc, { node }) => {
			const {
				file: { url },
			} = node
			acc[url] = node
			return acc
		}, {})
	}, [data.allContentfulAsset])

	const html = useMemo(() => {
		return documentToReactComponents(markdown, ArticleRichTextOptions(images))
	}, [markdown, images])

	const categoryData = {
		"articles": {
			label: "Articles"
		},
		"members": {
			label: "Members"
		},
		"segments": {
			label: "Segments"
		},
		"charts": {
			label: "Charts"
		},
		"folders": {
			label: "Folders"
		},
		"csv": {
			label: "CSV"
		},
		"kpis": {
			label: "KPIs"
		},
		"dashboard": {
			label: "Dashboard"
		},
		"moe": {
			label: "MoE",
		},
		"data_collection": {
			label: "Data collection and methodology"
		},
		"research": {
			label: "Research"
		},
	}

	return (
		<div>
			<Layout engineering headerConfig={{ variant: "transparent-white" }} footerConfig={{ variant: "default", hideDemo:false, hideNewsletter:false}}>
				<CategoryHeader>
					<HeroContainer>
						<Left>
							<H3>Engineering Blog</H3>
							<P>
								We regularly update and add features to the Latana dashboard, and we collect all learning materials in
								this organized database.
							</P>
						</Left>
						<Right>
							<img src={Gears} alt={Gears.label} /> 
						</Right>
					</HeroContainer>
				</CategoryHeader>
				<StyledContainer>
					<Aside>
						{Object.keys(categoryData).map(category => {
							const findPost = allPosts.find(post => post.node.category === category)

							if(!findPost) {
								return null
							}
							const firstCategoryPost = findPost.node
							
							if (category === currentPost.category) {
								return (
									<div>
										<button type="button">{categoryData[category].label}</button>
										<CategoryLinks>
											{categoryLinks.map(categoryLink => {
												return (
													<Link
														className={categoryLink.slug === slug ? "selected" : ""}
														to={`/engineering/${categoryLink.slug}`}
													>
														{categoryLink.title}
													</Link>
												)
											})}
										</CategoryLinks>
									</div>
								)
							}
							return (
								<Link to={`/engineering/${firstCategoryPost.slug}`}>
									<button type="button">{categoryData[category].label}</button>
								</Link>
							)
						})}
					</Aside>
					<ArticleContent>
						<ArticleTitle>{title}</ArticleTitle>
						<div>
							<H3> {author} · {date}</H3>
							<P>{tag.join(' · ')}</P>
						</div>
						{html}
					</ArticleContent>
				</StyledContainer>
			</Layout>
		</div>
	)
}

export default EngineeringPost
